import cn from 'classnames';
import Select from 'components/select';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ALL_ADVISORS_DEFAULT_VALUE } from '../advisor-filter/constants';
import './styles.scss';

const SupervisorFilter = ({
  isDisabled,
  supervisors,
  className,
  allowAll,
  filter,
  onChange,
  onSearch
}) => {
  const { supervisorByManager, authProvider, advisorProvider, user } = useContext(AdvisorContext);
  const [selectedSupervisorId, setSelectedSupervisorId] = useState(
    supervisorByManager ?? ALL_ADVISORS_DEFAULT_VALUE
  );

  useEffect(() => {
    advisorProvider.es.supervisorList(); // Load supervisors list on mount
  }, []);

  useEffect(() => {
    if (!_.isNil(supervisorByManager) && supervisorByManager !== selectedSupervisorId)
      setSelectedSupervisorId(supervisorByManager);
  }, [supervisorByManager]);

  if (!user || !supervisors) return null;

  const isUserInSupervisors = supervisors.some(supervisor => supervisor.id === user.advisor.id);

  const supervisorOptions = _.orderBy(
    supervisors
      .filter(supervisor => supervisor.id !== user.advisor.id)
      .filter(supervisor => {
        if (supervisorByManager === supervisor.id) return true;
        if (filter.withAccounts) return supervisor.total_accounts > 0;
        if (filter.withClients) return supervisor.total_clients > 0;
        if (filter.withProspects) return supervisor.total_prospects > 0;
        if (filter.withHouseholds) return supervisor.total_households > 0;
        if (filter.withModelPortfolios) return supervisor.total_model_portfolios > 0;
        return true;
      })
      .map(supervisor => ({ label: supervisor.name, value: supervisor.id })),
    ['label', 'value'],
    ['desc', 'asc', 'asc']
  );

  const hasSupervisorOptions = !_.isEmpty(supervisorOptions);
  if (isUserInSupervisors) {
    const meOption = {
      label: `[Me] ${user.first_name} ${user.last_name}`,
      value: hasSupervisorOptions ? user.advisor.id : ALL_ADVISORS_DEFAULT_VALUE
    };
    supervisorOptions.unshift(meOption);
  }

  if (allowAll && hasSupervisorOptions)
    supervisorOptions.unshift({ label: 'All Supervisors', value: ALL_ADVISORS_DEFAULT_VALUE });

  const handleChange = selected => {
    const { value } = selected;
    if (!supervisorOptions.some(a => a.value === value)) return;
    setSelectedSupervisorId(value);
    if (supervisorByManager !== value) authProvider.setSupervisorByManager(value);
    if (onChange) onChange(value, selected);
    else if (onSearch) onSearch({ page: 1, by_supervisor: value !== 0 ? value : undefined });
  };

  const selectedSupervisor = supervisorOptions.find(a => a.value === selectedSupervisorId);
  if (!selectedSupervisor)
    handleChange({ label: 'All Supervisors', value: ALL_ADVISORS_DEFAULT_VALUE });

  return (
    <div className={cn({ 'supervisor-filter-disabled': isDisabled })}>
      <Select
        className={className}
        onChange={handleChange}
        options={supervisorOptions}
        value={selectedSupervisor}
      />
    </div>
  );
};

SupervisorFilter.propTypes = {
  supervisors: PropTypes.arrayOf(PropTypes.object),
  allowAll: PropTypes.bool,
  className: PropTypes.string,
  filter: PropTypes.object,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  isDisabled: PropTypes.bool
};

SupervisorFilter.defaultProps = {
  supervisors: null,
  allowAll: true,
  className: '',
  filter: {},
  onChange: null,
  onSearch: null,
  isDisabled: false
};

export default connect(state => ({
  supervisors: state.advisors.supervisors
}))(SupervisorFilter);
